var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { staticClass: "p-3" },
    [
      _c(
        "a-form-model",
        {
          attrs: {
            model: _vm.tabStatusSource,
            rules: _vm.rules,
            "wrapper-col": { span: 12 },
            "label-col": { span: 7 }
          }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_prepayment_used") } },
            [
              _c(
                "a-space",
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("currency")(
                          _vm.tabStatusSource.prepaymentUsed.total
                        )
                      )
                    )
                  ]),
                  _c("a-icon", {
                    attrs: { type: "select" },
                    on: {
                      click: function($event) {
                        return _vm.viewMore("prepaymentUsed")
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_remaining_invoice_amount") } },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("currency")(
                      _vm.tabStatusSource.remainingInvoiceAmount
                    )
                  )
                )
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_return") } },
            [
              _c(
                "a-space",
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm._f("currency")(_vm.tabStatusSource.return.total)
                      )
                    )
                  ]),
                  _c("a-icon", {
                    attrs: { type: "select" },
                    on: {
                      click: function($event) {
                        return _vm.viewMore("return")
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_join_invoice") } },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.tabStatusSource.invoiceJoinNumber || "-"))
              ])
            ]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_join_invoice_date") } },
            [
              _c("span", [
                _vm._v(
                  _vm._s(_vm._f("date")(_vm.tabStatusSource.invoiceJoinDate))
                )
              ])
            ]
          )
        ],
        1
      ),
      _c("InvoiceModalPrepaymentUsed", {
        attrs: {
          "prop-source": _vm.tabStatusSource.prepaymentUsed.data.prepaymentLines
        },
        model: {
          value: _vm.modal.prepaymentUsed,
          callback: function($$v) {
            _vm.$set(_vm.modal, "prepaymentUsed", $$v)
          },
          expression: "modal.prepaymentUsed"
        }
      }),
      _c("InvoiceModalReturn", {
        attrs: { "prop-source": _vm.tabStatusSource.return.data },
        model: {
          value: _vm.modal.return,
          callback: function($$v) {
            _vm.$set(_vm.modal, "return", $$v)
          },
          expression: "modal.return"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }