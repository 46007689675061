
















































import { Decimal } from "decimal.js-light";
import Vue from "vue";
import { mapGetters, mapMutations, mapState } from "vuex";
export default Vue.extend({
  name: "InvoiceTabStatus",
  components: {
    InvoiceModalPrepaymentUsed: () => import(/*webpackPrefetch: true */"./InvoiceModalPrepaymentUsed.vue"),
    InvoiceModalReturn: () => import(/*webpackPrefetch: true */"./InvoiceModalReturn.vue"),
  },
  data() {
    return {
      rules: {},
      modal: {
        prepaymentUsed: false,
        return: false,
      },
    };
  },
  computed: {
    ...mapState({
      tabStatusSource: (st: any) => st.invoiceStore.tabStatusSource,
      tabApplyPrepaymentSource: (st: any) => st.invoiceStore.tabApplyPrepaymentSource,
    }),
    ...mapGetters({
      getTotalAppliedPrepayment: "invoiceStore/GET_TOTAL_APPLIED_PREPAYMENT",
    })
  },
  watch: {
    "tabApplyPrepaymentSource.length"(): void {
      // this.calcAccountReceivable();
    },
  },
  created() {
    // this.calcAccountReceivable();
  },
  methods: {
    ...mapMutations({
      setTabStatusSource: "invoiceStore/SET_TAB_STATUS_SOURCE",
    }),
    viewMore(source: "prepaymentUsed" | "return"): void {
      this.modal[source] = true;
    },
    calcAccountReceivable(): void {
      const total = new Decimal(this.tabStatusSource.total || 0);
      const prepayment = this.getTotalAppliedPrepayment || 0;
      const receipt = 0;
      const accReceivable = total.minus(prepayment).minus(receipt).toNumber();
      this.setTabStatusSource({
        ...this.tabStatusSource,
        accountReceivable: accReceivable,
      });
    },
  }
});
